.hidden{
  display: none;
}

.hide{
  display: none;
}

.temp-icon{
  padding-top: 10px;
  width: 50px;
  margin: 0 15px;
}

.main-menu{
  display: flex;
}

.left-menu{
  width: 50%;
}

.right-menu{
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

header {
    width: 100%;
}

a{
  display: inline-flex;
}

.main-menu-item{
  display: none;
}

.menu-icon{
  width: 40px;
}

.menu-icon:hover{
  width: 50px;
}

.main-icon{
  width: 100px;
  padding-left: 20px;
  padding-top: 10px;
}

.frEMrd:hover{
  background: url('/src/images/CONNECT-WALLET-300x140b.png');
}

.row{
  display:flex;
  border: none;
  color:white;
}

.single-col, .team, .gallery{
  width: 100%;
  text-align: center;
  justify-content: center;
}

.rarity-con{
  width: 100%;
  text-align: center;
  margin: 25px 0;
}

.rare {
  width: 75%;
  margin: auto;
}

.main-hero{
  width: 80%;
}

.small-text,.rare-text,.start-text{
  padding: 0;
  margin: 0;
  margin-top: 8rem;
  font-size: 20px;
  font-family: 'Yanone Kaffeesatz', sans-serif;
}
.start-text{
  margin-top: 4rem;
}

.rare-text{
    margin-top: 5px;
}

.s-bigger{
  font-size: 22px;
}

.med-text, .start-slogan{
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 54px;
  font-family: 'Amatic SC', cursive;
  margin-top: 5rem;
}

.start-slogan{
  margin-top: 0;
}

.big-text{
  padding: 0px 25px;
  font-family: 'Montserrat', sans-serif;
}

.dark{
  color:black;
}

.container{
  display: flex;
  margin: auto;
}

.name{
  padding: 0;
  margin: 0;
  font-size: 36px;
  font-family: 'Amatic SC', cursive;
}

.role{
  margin: 5px 0;
  font-size: 24px;
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

.question{
  font-size: 28px;
  padding: 0 12px;
  color:darkorange;
  margin: 0;
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

.answer{
  padding: 0 12px;
  font-family: 'Montserrat', sans-serif;
}

h2{
  width: 100%;
}

.faq-row{
padding: 0 15px;
}

.one-fourth{
  width: calc(100% / 3);
  padding: 5px;
}

.img-size, .rarity-img{
  width: 90%;
}

.map-container{
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.map-size{
  width: 100%;
}

.double-col{
  padding: 10px;
}

.bg-container{
  width: 100%;
  height: 100%;
  background: url("/src/images/blueocean-800x1330-1.jpg" );
  background-repeat: no-repeat;
}

footer{
  width: 100%;
  padding: 20px 0;
  margin: 30px 0;
  text-align: center;
}

.bottom-icon{
  width: 120px;
}

.f-top{
  width: 100%;
  padding-bottom: 20px;
}

.f-bottom{
  display: flex;
  width: 100%;
  justify-content: center;
}

.b-menu-icon{
  width: 60px;
  padding: 0px 10px;
}

@media only screen and (min-width: 767px) {
  .bg-container{
    background: url("/src/images/blueocean-2000x1330-1.jpg" );
    background-repeat: no-repeat;
    background-size: contain;
  }

  .left-menu {
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;
    }

  .main-menu-item {
    display: block;
    font-size: 24px;
    text-decoration: none;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    color:black;
  }
    .main-menu-item:hover{
    color:darkorange;
  }

  .right-menu{
    width: 35%;
  }

  .small-text, .rare-text{
  font-size: 30px;
  }

  .start-text {
    margin-top: 6rem;
    font-size: 30px;
  }

  .med-text{
    font-size: 68px;
    margin-top: 8rem;
  }

  .start-slogan{
    font-size: 68px;
  }

  .main-hero{
    width: 60%;
    margin-bottom: 60px;
  }

  .one-fourth{
    width: calc(100% / 3);
    padding: 15px;
  }

  .one-fourth:hover{
    padding: 0px;
  }

  .img-size{
    width: 80%;
  }

  .container {
    display: block;
  }

  .double-col {
    display: flex;
    margin-bottom: 30px;
  }

  .map-container{
   width: 60%;
  }

  .rare{
    display: flex;
    width: 100%;
  }

  .rarity-img{
    width: 100%;
  }

  .rarity-con{
    padding: 25px;
  }

  .rarity-con:hover{
    color: darkorange;
  }

  .big-text {
    padding: 0px 70px;
  }

  .answer{
    padding: 0px 60px;
  }

  .question{
    padding: 0 30px;
  }

  footer{
  width: 100%;
  padding: 20px 0;
  margin: 30px 0;
  text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .main-icon{
    	content:url("/src/images/bogus-logo-340x100.png" );
      width: 320px;
  }

  .left-menu {
    width: 75%;
    padding-right: 20px;
  }

  .main-menu-item {
    font-size: 28px;
  }

  .right-menu{
    width: 25%;
    }

  .big-text {
    width: 80%;
    margin: auto;
  }

  .one-fourth{
    width: calc(100% / 4);
  }

  .hidden{
    display: initial;
  }

  .double-col{
    width: 100%;
  }

  .container{
    width: 80%;
  }

  .map-container{
    width: 35%;
    margin-top: 45px;
  }

  .img-size{
    width: 80%;
  }

  .name{
    font-size: 40px;
  }

  .role{
    font-size: 30px;
  }

  .question{
    padding: 0 18px;
  }

  .answer{
    padding: 0 18px;
    font-size: 18px;
    line-height: 26px;
  }

  .faq-row{
    padding: 0 14%;
  }

}

@media only screen and (min-width: 1280px) {
  .main-icon{
      width: 340px;
  }

  .main-menu{
    justify-content: space-between;
  }

  .left-menu {
    width: 60%;
  }

  .main-hero{
    width: 40%;
  }

  .small-text{
    margin-top: 10rem;
}

  .big-text{
    width: 70%;
    padding: 20px 70px;
  }

  .gallery{
    width: 90%;
    margin: auto;
  }
  .one-fourth {
    width: 21%;
  }

  .rarity-img{
    width: 80%;
  }

  .img-size{
    width: 70%;
  }

  .question{
    padding: 0 18px;
  }

  .answer{
    padding: 0 18px;
    font-size: 18px;
    line-height: 26px;
  }

  .faq-row{
    padding: 0 20%;
  }
}

@media only screen and (min-width: 1440px) {

  .left-menu {
   width: 55%;
  }

  .right-menu {
    width: 20%;
  }

  .main-hero{
    width: 35%;
  }

  .big-text {
    width: 65%;
  }

  .gallery{
    width: 85%;
  }

  .rarity-con {
    padding: 0;
  }

  .rare{
    width: 75%;
  }

  .rarity-img{
    width: 90%;
  }

  .container {
    width: 75%;
  }
}

@media only screen and (min-width: 1680px) {

  .right-menu {
    width: 15%;
    margin-right: 25px;
  }

  .menu-icon, .b-menu-icon{
    width: 50px;
  }

  .menu-icon:hover{
  width: 60px;
}

  .b-menu-icon {
    padding: 0;
  }

  .small-text, .rare-text, .start-text{
    font-size: 36px;
  }

  .med-text, .start-slogan {
    font-size: 74px;
  }

  .big-text {
    font-size: 22px;
  }
  .map-container{
    width: 25%;
  }

  .question {
    font-size: 36px;
  }

  .answer {
    font-size: 22px;
  }

  .f-bottom {
    width: 20%;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 20px;
  }

  .bottom-icon {
    width: 140px;
  }

}
@media only screen and (min-width: 1792px) {

  .main-menu {
    padding: 15px 20px;
    justify-content: space-between;
  }

  .left-menu{
    width: 48%;
  }

  .main-hero{
    width: 30%;
  }

  .small-text, .rare-text, .start-text {
    font-size: 30px;
  }

  .med-text,.start-slogan {
    font-size: 74px;
  }

  .big-text {
    font-size: 18px;
    width: 55%;
  }

  .gallery{
    width: 70%;
  }

  .rare{
    width: 60%;
  }

  .double-col {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }

  .question {
    font-size: 32px;
  }

  .answer {
    font-size: 22px;
  }
}
@media only screen and (min-width: 2000px) {
  .map-container{
    width: 15%;
  }
}
